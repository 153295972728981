import { useEffect, useState } from "react";
import { ClubType } from "../../types";
import { API } from "../../queries/api";

export default function useGetClubs() {
	const [clubs, setClubs] = useState<ClubType[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const getClubs = async () => {
		try {
			setIsLoading(true);
			const { data, status } = await API.get(`clubs`);
			if (status === 200 && !!data.length) {
				setClubs(data);
			} else {
				setClubs([]);
			}
		} catch (error) {
			console.log(error);
			setClubs([]);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getClubs();
	}, []);

	return {
		isLoading,
		clubs,
	};
}
