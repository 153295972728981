import { useEffect, useState } from "react";
import { CoalitionType } from "../../types";
import { API } from "../../queries/api";

export default function useGetCoalitions() {
	const [coalitions, setCoalitions] = useState<CoalitionType[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchCoalitions = async () => {
		setIsLoading(true);
		try {
			const { data, status } = await API.get("coalitions");
			if (status === 200 && !!data) {
				setCoalitions(data);
			}
		} catch (error) {
			console.error("Error fetching Coalitions:", error);
		}
		setIsLoading(false);
	};

	const refetch = () => {
		fetchCoalitions();
	};

	useEffect(() => {
		fetchCoalitions();
	}, []);

	return {
		isLoading,
		coalitions,
		refetch,
	};
}
