import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import style from "./css/coalitions.module.scss";
import { Button } from "@mui/material";
import useGetCoalitions from "../../hooks/network/useGetCoalitions";
import { Link } from "react-router-dom";
import useConfirmModal from "../../hooks/useConfirmModal";
import useDeleteCoalitions from "../../hooks/network/useDeleteCoalition";

const CoalitionsList = () => {
	const { isLoading, coalitions, refetch } = useGetCoalitions();
	const { deleteCoalition } = useDeleteCoalitions({
		onSuccess: () => refetch(),
	});

	const { openConfirmModal } = useConfirmModal({
		title: `Are you really sure you want to delete this coalition?`,
		onConfirm: ({ itemData }) => {
			deleteCoalition(itemData.id);
		},
	});

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Coalitions
			</Typography>
			{isLoading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Public Access</TableCell>
							<TableCell>Market Linked</TableCell>
							<TableCell>Library Linked</TableCell>
							<TableCell>Networking Linked</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!!coalitions.length ? (
							coalitions.map((row) => (
								<TableRow key={row.id} sx={{ cursor: "pointer" }}>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.description}</TableCell>
									<TableCell>{String(row.publicAccess)}</TableCell>
									<TableCell>{String(row.marketLinked)}</TableCell>
									<TableCell>{String(row.libraryLinked)}</TableCell>
									<TableCell>{String(row.networkingLinked)}</TableCell>
									<TableCell>
										<div className={style.tableActionsWrapper}>
											<Link to={`/coalition/edit/${row.id}`}>
												<Button variant="contained" color="info" size="small">
													Edit
												</Button>
											</Link>
											<Button
												variant="contained"
												color="error"
												onClick={() => {
													openConfirmModal({ itemData: row });
												}}
												size="small"
											>
												Delete
											</Button>
										</div>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={12} align="center">
									No coalitions found
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					paddingTop: "16px",
				}}
			>
				<Link to={`/coalition/create`}>
					<Button variant="contained" color="primary">
						Add new coalition
					</Button>
				</Link>
			</div>
		</Paper>
	);
};

export default CoalitionsList;
