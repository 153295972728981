import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useGetCoalition from "../../hooks/network/useGetCoalition";
import style from "./css/coalitions.module.scss";
import useGetClubs from "../../hooks/network/useGetClubs";
import { Autocomplete } from "@mui/material";
import { ClubType } from "../../types";
import useUpdateCoalition from "../../hooks/network/useUpdateCoalition";

export default function CoalitionEdit() {
	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const { coalitionId } = useParams<{ coalitionId: string }>();
	const [publicAccess, setPublicAccess] = useState<boolean>(false);
	const [marketLinked, setMarketLinked] = useState<boolean>(false);
	const [libraryLinked, setLibraryLinked] = useState<boolean>(false);
	const [networkingLinked, setNetworkingLinked] = useState<boolean>(false);
	const [clubs, setClubs] = useState<ClubType[]>([]);
	const navigate = useNavigate();
	const { isLoading, coalition } = useGetCoalition(coalitionId || "");
	const { clubs: allClubs } = useGetClubs();

	const { saveCoalition, isLoading: isLoadingCoalition } = useUpdateCoalition({
		onSuccess: () => navigate("/coalitions"),
	});

	const publicAccessHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPublicAccess(event.target.checked);
	};

	const marketLinkedHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMarketLinked(event.target.checked);
	};

	const libraryLinkedHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLibraryLinked(event.target.checked);
	};

	const networkingLinkedHandle = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setNetworkingLinked(event.target.checked);
	};

	useEffect(() => {
		if (coalition) {
			setName(coalition.name);
			setDescription(coalition.description);
			setPublicAccess(coalition.publicAccess);
			setMarketLinked(coalition.marketLinked);
			setLibraryLinked(coalition.libraryLinked);
			setNetworkingLinked(coalition.networkingLinked);
			setClubs(coalition.clubs);
		}
	}, [coalition]);

	const saveCoalitionHandle = async () => {
		// const clubIds = clubs.map((club) => club.id);
		const payload = {
			id: coalitionId,
			name,
			description,
			publicAccess,
			clubs, //: clubIds,
			marketLinked,
			libraryLinked,
			networkingLinked,
		};
		saveCoalition(payload);
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				{`${coalitionId ? "Edit" : "Create"} Coalition`}
			</Typography>
			{(isLoading || isLoadingCoalition) && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<Grid container spacing={2} sx={{ margin: "8px 0" }}>
				<Grid item xs={4}>
					<TextField
						name="name"
						label="Name"
						fullWidth
						variant="outlined"
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="description"
						label="Description"
						fullWidth
						variant="outlined"
						value={description}
						onChange={(event) => setDescription(event.target.value)}
					/>
				</Grid>
			</Grid>
			<div className={style.editSwitchSection}>
				<FormControlLabel
					sx={{ marginBottom: "8px" }}
					control={
						<Switch
							color="info"
							onChange={publicAccessHandle}
							checked={publicAccess}
						/>
					}
					label="Public Access"
				/>
				<FormControlLabel
					sx={{ marginBottom: "8px" }}
					control={
						<Switch
							color="info"
							onChange={marketLinkedHandle}
							checked={marketLinked}
						/>
					}
					label="Market Linked"
				/>
				<FormControlLabel
					sx={{ marginBottom: "8px" }}
					control={
						<Switch
							color="info"
							onChange={libraryLinkedHandle}
							checked={libraryLinked}
						/>
					}
					label="Library Linked"
				/>
				<FormControlLabel
					sx={{ marginBottom: "8px" }}
					control={
						<Switch
							color="info"
							onChange={networkingLinkedHandle}
							checked={networkingLinked}
						/>
					}
					label="Networking Linked"
				/>
			</div>
			<Grid item xs={12}>
				<Autocomplete
					multiple
					options={allClubs || []}
					getOptionLabel={(option) => option?.name}
					defaultValue={clubs}
					filterSelectedOptions
					onChange={(e, value) => setClubs(value)}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderInput={(params) => (
						<TextField {...params} label="Clubs" placeholder="Clubs" />
					)}
				/>
			</Grid>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					paddingTop: "16px",
				}}
			>
				<Button
					variant="contained"
					color="primary"
					onClick={saveCoalitionHandle}
				>
					Save
				</Button>
			</div>
		</Paper>
	);
}
