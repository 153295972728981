export const IMAGE_TYPES = {
	PROFILE_PHOTO: "PROFILE_PHOTO",
	POST_IMAGE: "POST_IMAGE",
	MESSAGE_FILE: "MESSAGE_FILE",
	PREVIEW: "PREVIEW",
	POST_VIDEO: "POST_VIDEO",
	POST_FILE: "POST_FILE",
	CLUB_PHOTO: "CLUB_PHOTO",
	CHAT_PHOTO: "CHAT_PHOTO",
	CHAT_VIDEO_PREVIEW: "CHAT_VIDEO_PREVIEW",
	COMMENT_IMAGE: "COMMENT_IMAGE",
	COMMENT_VIDEO: "COMMENT_VIDEO",
	WIKI_VIDEO_PREVIEW: "WIKI_VIDEO_PREVIEW",
	COMPANY_PHOTO: "COMPANY_PHOTO",
	LIBRARY_IMAGE: "LIBRARY_IMAGE",
};

export const CHAT_TYPES = {
	CLUB: {
		value: "CLUB",
		label: "Usual channel (with user's messages and treads)",
	},
	CLUB_CHANEL_WITH_COMMENTS: {
		value: "CLUB_CHANEL_WITH_COMMENTS",
		label: "Club channel (channel posts only, with replays)",
	},
	CLUB_CHANNEL: {
		value: "CLUB_CHANNEL",
		label: "Club channel (channel posts only, without replays)",
	},
	COALITION: {
		value: "COALITION",
		label: "Usual coalition (with user's messages and treads)",
	},
	COALITION_CHANEL: {
		value: "COALITION_CHANEL",
		label: "Coalition channel (channel posts only, without replays)",
	},
	COALITION_CHANEL_WITH_COMMENTS: {
		value: "COALITION_CHANEL_WITH_COMMENTS",
		label: "Coalition channel (channel posts only, with replays)",
	},
};

export const KEYWORD_TYPES = {
	PERSONAL_INTERESTS: "PERSONAL_INTERESTS",
	INDUSTRY: "INDUSTRY",
	INDUSTRY_CATEGORIES: "INDUSTRY_CATEGORIES",
	COMPANY_ROLE: "COMPANY_ROLE",
};

export const EDIT_KW_MODAL_TYPES = {
	NEW: "NEW",
	EDIT: "EDIT",
	CREATE_CHILD: "CREATE_CHILD",
};

export const GENERAL_STATUSES = {
	PENDING: "PENDING",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED",
};

export const ADMIN_ROLE = {
	ADMIN: "ADMIN",
	CLUB_ADMIN: "CLUB_ADMIN",
	CLUB_MODERATOR: "CLUB_MODERATOR",
};

export const ADMIN_ROLES = Object.values(ADMIN_ROLE);

export const LIBRARY_ITEM_PART_TYPE = {
	FILE: "FILE",
	IMAGE: "IMAGE",
	VIDEO: "VIDEO",
	CALL: "CALL",
	AUDIO: "AUDIO",
	GIPHY: "GIPHY",
	VOICE_RECORDING: "VOICE_RECORDING",
	LINK_PREVIEW: "LINK_PREVIEW",
};

export const LIBRARY_ITEM_PART_TYPES = Object.values(LIBRARY_ITEM_PART_TYPE);
