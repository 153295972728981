import { useEffect, useState } from "react";
import { CoalitionType } from "../../types";
import { API } from "../../queries/api";

export default function useGetCoalition(id: string) {
	const [coalition, setCoalition] = useState<CoalitionType>();
	const [isLoading, setIsLoading] = useState(false);

	const fetchCoalition = async () => {
		if (!id) {
			return;
		}
		setIsLoading(true);
		try {
			const { data, status } = await API.get(`coalition/${id}`);
			if (status === 200 && !!data) {
				setCoalition(data);
			}
		} catch (error) {
			console.error("Error fetching Coalition:", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchCoalition();
	}, []);

	return {
		isLoading,
		coalition,
	};
}
