import { useState } from "react";
import { CoalitionPayloadType } from "../../types";
import { API } from "../../queries/api";
import useSnackbar from "../useSnackbar";

export default function useUpdateCoalition({
	onSuccess,
	onError,
}: {
	onSuccess: () => void;
	onError?: () => void;
}) {
	const [isLoading, setIsLoading] = useState(false);
	const openSnackbar = useSnackbar();

	const saveCoalition = async (payload: CoalitionPayloadType) => {
		if (!payload.name) {
			openSnackbar("Name is required");
			return;
		}
		setIsLoading(true);
		try {
			const { data, status } = await API.post(`coalition`, payload);
			if (status === 200 && !!data) {
				openSnackbar("Coalition saved successfully");
				onSuccess();
			} else {
				openSnackbar("Error saving Coalition");
				onError && onError();
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error saving Coalition");
			onError && onError();
		}
		setIsLoading(false);
	};

	return {
		isLoading,
		saveCoalition,
	};
}
